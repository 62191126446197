<template>
	<div ref="productsRef">
		<v-row v-if="loading">
			<v-col cols="12" md="6" lg="3" v-for="loader in 8" :key="loader">
				<v-skeleton-loader
					v-bind="attrs"
					type="card"
					height="400"
					width="100%"
				></v-skeleton-loader>
			</v-col>
		</v-row>

		<div v-else class="tw-pb-6">
			<div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between">
				<h2
					class="tw-text-xl tw-leading-none tw-font-semibold tw-mb-2 md:tw-mb-7 xl:tw-mb-0 tw-capitalize"
				>
					{{ currentTab }}
				</h2>
				<div class="tw-flex tw-items-center tw-flex-1 sm:tw-flex-none">
					<!-- TABS -->
					<div v-if="tabs.length > 0" class="tw-flex tw-mr-4">
						<v-btn
							v-for="tab in tabs"
							:key="tab.value"
							:text="tab.value !== currentTab"
							:color="tab.value === currentTab ? '#722432' : '#D8D8D8'"
							depressed
							class="lg:tw-h-12"
							style="min-width: 114px"
							@click="onChangeTab(tab)"
						>
							<span
								class="tw-normal-case tw-text-15p tw-font-normal"
								:class="
									tab.value === currentTab
										? 'tw-text-white'
										: 'tw-text-CB'
								"
								>{{ tab.text }}</span
							>
						</v-btn>
					</div>

					<v-btn
						@click="sortByDrawer = !sortByDrawer"
						class="tw-rounded tw-w-12 tw-h-12 tw-me-4 tw-ms-auto sm:tw-ms-0"
						color="#D8D8D8"
						icon
						outlined
						tile
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18.605"
							height="18.144"
							viewBox="0 0 18.605 18.144"
						>
							<g id="ascending-sort" transform="translate(0 -3.736)">
								<path
									id="Path_34228"
									data-name="Path 34228"
									d="M9.844,4.971V4.354a.618.618,0,0,0-.618-.618H.618A.618.618,0,0,0,0,4.354v.618a.618.618,0,0,0,.618.618H9.226A.618.618,0,0,0,9.844,4.971Z"
								/>
								<path
									id="Path_34229"
									data-name="Path 34229"
									d="M8.066,66.736H.618A.618.618,0,0,0,0,67.354v.618a.618.618,0,0,0,.618.618H8.066a.618.618,0,0,0,.618-.618v-.618A.618.618,0,0,0,8.066,66.736Z"
									transform="translate(0 -59.109)"
								/>
								<path
									id="Path_34230"
									data-name="Path 34230"
									d="M6.906,129.736H.618a.618.618,0,0,0-.618.618v.618a.618.618,0,0,0,.618.618H6.906a.618.618,0,0,0,.618-.618v-.618A.618.618,0,0,0,6.906,129.736Z"
									transform="translate(0 -118.217)"
								/>
								<path
									id="Path_34231"
									data-name="Path 34231"
									d="M5.746,199.736H.618a.618.618,0,0,0-.618.618v.618a.618.618,0,0,0,.618.618H5.746a.618.618,0,0,0,.618-.618v-.618A.618.618,0,0,0,5.746,199.736Z"
									transform="translate(0 -183.894)"
								/>
								<path
									id="Path_34232"
									data-name="Path 34232"
									d="M4.586,262.736H.618a.618.618,0,0,0-.618.618v.618a.618.618,0,0,0,.618.618H4.586a.618.618,0,0,0,.618-.618v-.618A.618.618,0,0,0,4.586,262.736Z"
									transform="translate(0 -243.002)"
								/>
								<path
									id="Path_34233"
									data-name="Path 34233"
									d="M163.051,21.592l-.437-.437a.618.618,0,0,0-.874,0l-2.124,2.124V9.354A.618.618,0,0,0,159,8.736h-.618a.618.618,0,0,0-.618.618V23.28l-2.124-2.124a.618.618,0,0,0-.874,0l-.437.437a.618.618,0,0,0,0,.874l3.924,3.924a.618.618,0,0,0,.874,0l3.924-3.924A.618.618,0,0,0,163.051,21.592Z"
									transform="translate(-144.627 -4.691)"
								/>
							</g>
						</svg>
					</v-btn>
					<v-btn
						@click="filterDrawer = !filterDrawer"
						class="tw-rounded tw-w-12 tw-h-12"
						color="#D8D8D8"
						icon
						outlined
						tile
					>
						<v-icon color="black">mdi mdi-filter</v-icon>
					</v-btn>
				</div>
			</div>

			<!-- CATEGORIES -->
			<div class="tw-mt-4 tw-h-6 tw-mb-4">
				<button
					v-if="!showAllCategories"
					class="tw-flex tw-items-center tw-text-F2 tw-cursor-pointer tw-ms-auto"
					@click="showAllCategories = true"
				>
					{{ $t('viewAll') }}
					<v-icon color="#F2CA51">mdi-chevron-right</v-icon>
					<v-icon color="#F2CA51" class="tw--ms-4"
						>mdi-chevron-right</v-icon
					>
				</button>
			</div>

			<div
				class="tw-flex tw-overflow-hidden tw--ms-4 tw--mt-4 lg:tw--ms-9 lg:tw--mt-9"
				:class="{ 'tw-flex-wrap': showAllCategories }"
			>
				<template
					v-if="
						$productCategoriesForMarketing.isRefreshing ||
							!$productCategoriesForMarketing.isLoaded
					"
				>
					<CategoryCardSkeleton v-for="i in 11" :key="i" />
				</template>

				<template v-else>
					<CategoryCard
						v-for="(item, index) in $productCategoriesForMarketing.data"
						:key="index"
						:item="item"
						:disabled="$products.isRefreshing"
						:isSelected="filters.mainCategory === item.id"
						class="tw-cursor-pointer tw-flex-none tw-ms-4 tw-mt-4 lg:tw-ms-9 lg:tw-mt-9"
						@onClick="onSelectCategory"
					/>
				</template>
			</div>

			<v-tabs-items v-model="currentTab">
				<v-tab-item value="products">
					<v-row v-if="!$products.isLoaded" class="tw-mt-9">
						<v-col
							v-for="i in 12"
							:key="i"
							cols="12"
							sm="6"
							md="4"
							lg="3"
							xl="2"
						>
							<ProductCardSkeleton />
						</v-col>
					</v-row>

					<!-- PRODUCTS -->
					<v-row class="tw-mt-9">
						<v-col
							v-for="(item, i) in $products.data"
							:key="i"
							cols="12"
							sm="6"
							md="4"
							lg="3"
							xl="2"
						>
							<ProductCard
								@onClick="goToSingleProduct"
								@onHover="handleOnHover"
								@fetchFavorite="refetchFavorite"
								:productInfo="
									selectedType == 'products' ? item : item.products[0]
								"
								class="tw-flex-none"
								:isGreyQuantityBg="false"
							/>
						</v-col>
					</v-row>

					<div
						v-if="$products.isLoaded"
						v-intersect="onIntersect"
						class="tw-text-center"
					>
						<v-progress-circular
							v-if="$products.isRefreshing"
							indeterminate
							color="primary"
							size="64"
							width="6"
							class="tw-mt-8 xl:tw-mt-12"
						/>
					</div>
				</v-tab-item>
				<v-tab-item value="offers">
					<v-row v-if="!$offers.isLoaded" class="tw-mt-9">
						<v-col
							v-for="i in 6"
							:key="i"
							cols="12"
							sm="6"
							md="4"
							lg="3"
							xl="2"
						>
							<ProductCardSkeleton />
						</v-col>
					</v-row>
					<!-- OFFERS -->
					<v-row class="tw-mt-9">
						<v-col
							v-for="(offer, i) in $offers.data"
							:key="i"
							cols="12"
							sm="6"
							md="4"
							lg="3"
							xl="2"
						>
							<OfferCard :offer="offer" />
						</v-col>
					</v-row>

					<div
						v-if="$offers.isLoaded"
						v-intersect="onIntersectOffers"
						class="tw-text-center"
					>
						<v-progress-circular
							v-if="$offers.isRefreshing"
							indeterminate
							color="primary"
							size="64"
							width="6"
							class="tw-mt-8 xl:tw-mt-12"
						/>
					</div>
				</v-tab-item>
			</v-tabs-items>

			<div v-if="isEmpty" class="pa-16">
				<v-img
					width="400"
					class="mx-auto"
					src="@/assets/images/empty.svg"
				/>
				<p class="mt-16 tw-text-center">{{ $t('noItemsFound') }}</p>
			</div>
		</div>

		<!-- DIALOG HOVER -->
		<v-dialog
			content-class="tw-rounded-2xl tw-shadow-none"
			v-model="showProductDialog"
			max-width="1055"
		>
			<product-card-full
				dense
				:product="onHoverProduct"
				class="tw-bg-white tw-rounded-2xl tw-p-8"
			/>
		</v-dialog>

		<!-- SORTING -->
		<v-navigation-drawer
			v-model="sortByDrawer"
			fixed
			temporary
			:right="!$isRtl()"
			class="2xl:tw-rounded-3xl lg:tw-rounded-xl tw-rounded-lg tw-w-64 md:tw-w-80 2xl:tw-w-420 tw-z-30"
		>
			<div class="tw-px-4 md:tw-px-10 tw-pt-6 md:tw-pt-10 xl:tw-pt-16">
				<div class="tw-flex tw-items-center tw-space-s-10">
					<v-btn
						@click="sortByDrawer = !sortByDrawer"
						class="tw-w-12 tw-h-12"
						icon
					>
						<v-icon large>mdi-chevron-left</v-icon>
					</v-btn>
					<h3 class="tw-text-2xl tw-font-semibold">{{ $t('sort-by') }}</h3>
				</div>
				<div class="tw-mt-5 xl:tw-mt-11">
					<v-radio-group v-model="filters.orderByColumn">
						<v-radio
							color="#F2CA51"
							class="tw-mt-6 xl:tw-mt-8"
							:value="'highToLow'"
						>
							<template v-slot:label>
								<div
									class="tw-text-xl tw-text-black tw-ms-4 xl:tw-ms-8"
								>
									{{ $t('high-to-low-price') }}
								</div>
							</template>
						</v-radio>
						<v-radio
							color="#F2CA51"
							class="tw-mt-6 xl:tw-mt-8"
							:value="'lowToHigh'"
						>
							<template v-slot:label>
								<div
									class="tw-text-xl tw-text-black tw-ms-4 xl:tw-ms-8"
								>
									{{ $t('low-to-high-price') }}
								</div>
							</template>
						</v-radio>
						<v-radio
							color="#F2CA51"
							class="tw-mt-6 xl:tw-mt-8"
							:value="'newItems'"
						>
							<template v-slot:label>
								<div
									class="tw-text-xl tw-text-black tw-ms-4 xl:tw-ms-8"
								>
									{{ $t('new-items') }}
								</div>
							</template>
						</v-radio>
					</v-radio-group>
				</div>
			</div>
			<template v-slot:append>
				<div
					block
					class="tw-mx-4 lg:tw-mx-10 tw-mb-4 md:tw-mb-8 xl:tw-mb-14"
				>
					<v-btn
						:loading="$products.isRefreshing || $offers.isRefreshing"
						dark
						block
						depressed
						color="#722432"
						class="tw-rounded-lg md:tw-h-14"
						@click="sortProduts"
					>
						<span
							class="tw-text-lg tw-capitalize tw-font-medium tw-text-white"
							>{{ $t('sort-result') }}</span
						>
					</v-btn>
				</div>
			</template>
		</v-navigation-drawer>

		<!-- FILTERING -->
		<v-navigation-drawer
			v-model="filterDrawer"
			fixed
			temporary
			:right="!$isRtl()"
			class="tw-rounded-3xl tw-w-64 md:tw-w-80 2xl:tw-w-420 tw-z-30"
		>
			<div class="tw-px-4 md:tw-px-10 tw-pt-16">
				<div class="tw-flex tw-items-center tw-space-s-10">
					<!-- class="tw-w-12 tw-h-12" -->
					<v-btn
						icon
						:small="$vuetify.breakpoint.mdAndDown"
						@click="filterDrawer = !filterDrawer"
					>
						<v-icon :large="$vuetify.breakpoint.lgAndAup"
							>mdi-chevron-left</v-icon
						>
					</v-btn>
					<h3 class="tw-text-2xl tw-font-semibold">{{ $t('filters') }}</h3>
				</div>
				<div>
					<v-text-field
						height="54"
						class="custom-outline-text-field tw-rounded-md tw-my-4 lg:tw-my-11"
						outlined
						hide-details="false"
						v-model="filters.query"
					>
						<v-icon slot="prepend-inner" color="#413434"
							>mdi-magnify</v-icon
						>
					</v-text-field>
					<div>
						<h2
							class="tw-text-19px tw-font-medium tw-mb-4 xl:tw-mb-8 tw-text-black"
						>
							{{ $t('show-categories') }}
						</h2>
						<div
							class="tw-flex tw-space-s-2 tw-flex-wrap tw--ms-2 tw--mt-2"
						>
							<div
								v-for="(category,
								i) in $productCategoriesForMarketing.data"
								:key="i"
								@click="filters.mainCategory = category.id"
								class="tw-rounded-md tw-py-2.5 tw-ps-4 tw-pe-5 tw-mt-2 tw-ms-2 tw-cursor-pointer"
								:class="
									category.id == filters.mainCategory
										? 'tw-bg-F2 '
										: 'tw-border tw-border-E9 '
								"
							>
								<span
									:class="
										category.id == filters.mainCategory
											? ' tw-text-white'
											: 'tw-text-black tw-opacity-70'
									"
									class="tw-text-base"
									>{{ category.name }}</span
								>
							</div>
						</div>
					</div>
					<div
						v-if="
							selectedCategory &&
								selectedCategory.childCategories &&
								selectedCategory.childCategories.length
						"
						class="lg:tw-mt-8 tw-mt-4"
					>
						<h2
							class="tw-text-19px tw-font-medium tw-mb-4 xl:tw-mb-8 tw-text-black"
						>
							{{ $t('sub-categories') }}
						</h2>
						<div
							class="tw-flex tw-space-s-2 tw-flex-wrap tw--ms-2 tw--mt-2"
						>
							<div
								v-for="(subCategory,
								i) in selectedCategory.childCategories"
								:key="i"
								:class="
									subCategory.id == filters.subCategory
										? 'tw-bg-F2 '
										: 'tw-border tw-border-E9 '
								"
								class="tw-rounded-md tw-py-2.5 tw-ps-4 tw-pe-5 tw-mt-2 tw-ms-2 tw-cursor-pointer"
								@click="filters.subCategory = subCategory.id"
							>
								<span
									:class="
										subCategory.id == filters.subCategory
											? ' tw-text-white'
											: 'tw-text-black tw-opacity-70'
									"
									class="tw-text-base"
									>{{ subCategory.name }}</span
								>
							</div>
						</div>
					</div>
					<div class="lg:tw-my-8 tw-my-4">
						<h2
							class="tw-text-19px tw-font-medium tw-mb-4 xl:tw-mb-8 tw-text-black"
						>
							{{ $t('price-range') }}
						</h2>
						<v-slider
							tick-size="32"
							track-color="#F4F4F4"
							class="price-range"
							thumb-color="#FFFFFF"
							hide-details="auto"
							color="#F2CA51"
							v-model="filters.priceRangeUp"
							hint="Im a hint"
							:min="minRange"
							:max="maxRange"
						></v-slider>
						<div
							class="tw-flex tw-justify-between tw-items-center tw-px-2 tw-mt-3"
						>
							<span class="tw-font-Rubik-text tw-text-D2"
								>{{ minRange }} {{ $t('sar') }}</span
							>
							<span class="tw-text-F2 tw-font-Rubik-text"
								>{{ filters.priceRangeUp }} {{ $t('sar') }}</span
							>
							<span class="tw-font-Rubik-text tw-text-D2"
								>{{ maxRange }} {{ $t('sar') }}</span
							>
						</div>
					</div>
				</div>
			</div>
			<template v-slot:append>
				<div block class="tw-mx-4 lg:tw-mx-10 tw-mb-14">
					<v-btn
						:loading="$products.isRefreshing || $offers.isRefreshing"
						dark
						block
						depressed
						color="#722432"
						class="tw-rounded-lg md:tw-h-14"
						@click="filterProducts"
					>
						<span
							class="tw-text-lg tw-capitalize tw-font-medium tw-text-white"
							>{{ $t('filter-results') }}</span
						>
					</v-btn>
				</div>
			</template>
		</v-navigation-drawer>
	</div>
</template>

<script>
// import VueSlickCarousel from "vue-slick-carousel";
import { mapActions, mapGetters } from 'vuex'
import { useScroll, numberWithCommas } from '../helpers'
import { changeLocationQuery, omitEmpties, queryString } from 'vuelpers'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import ProductCard from '../components/customs/ProductCard.vue'
import CategoryCard from '../components/customs/CategoryCard.vue'
import ProductCardFull from '../components/customs/ProductCardFull.vue'
import OfferCard from '../components/customs/OfferCard.vue'
import ProductCardSkeleton from '../components/customs/ProductCardSkeleton.vue'
import CategoryCardSkeleton from '../components/CategoryCardSkeleton.vue'

export default {
	name: 'Products',
	components: {
		OfferCard,
		ProductCard,
		CategoryCard,
		ProductCardFull,
		ProductCardSkeleton,
		CategoryCardSkeleton,
	},
	data() {
		return {
			scroll: null,
			showAllCategories: false,
			selectedType: 'products',
			showProductDialog: false,
			quantity: 1,
			onHoverProduct: null,
			sortByDrawer: false,
			loading: true,

			cacheFilter: {},
			filters: {
				query: '',
				subCategory: '',
				mainCategory: '',
				priceRangeUp: null,
				orderByColumn: '',
			},
			//carousel settings
			Settings: {
				arrows: true,
				focusOnSelect: true,
				infinite: true,
				speed: 500,
				initialSlide: 0,
				slidesToShow: 3,
				variableWidth: true,
			},
			minRange: 0,
			maxRange: 80000,
			filterDrawer: false,
			selectedImage: 0,
			currentTab: 'products',
		}
	},
	created() {
		this.currentTab = this.$route.query.tab || 'products'
		this.getProducts({ perPage: 24 })
		this.getCategoriesForProduct({
			perPage: 20,
			type: this.currentTab === 'products' ? 'product' : 'offer',
		})
		this.loading = false
	},
	mounted() {
		this.scroll = useScroll(this.$refs.productsRef)
	},
	beforeDestroy() {
		if (this.scroll) this.scroll.removeListener()
	},
	watch: {
		'$route.query.selectedCategory': {
			immediate: true,
			handler(val) {
				val && this.onSelectCategory(val)
			},
		},
		currentTab(tab) {
			changeLocationQuery(
				queryString.stringify({
					...queryString.parse(),
					tab,
				})
			)
			this.onFetchFunction()
			this.getCategoriesForProduct({
				perPage: 20,
				type: this.currentTab === 'products' ? 'product' : 'offer',
			})
		},
	},
	computed: {
		...mapGetters('offers', ['$offers']),
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('products', ['$products']),
		...mapGetters('category', ['$productCategoriesForMarketing']),
		tabs() {
			return [
				{ value: 'products', text: this.$t('products') },
				{ value: 'offers', text: this.$t('offers') },
			]
		},
		items() {
			return [
				{ title: this.$t('home-text'), icon: 'mdi-home-city' },
				{ title: this.$t('my-account'), icon: 'mdi-account' },
				{ title: this.$t('users'), icon: 'mdi-account-group-outline' },
			]
		},
		isEmpty() {
			if (this.currentTab === 'products') {
				return (
					this.$products.isLoaded &&
					!this.$products.isRefreshing &&
					!this.$products.data.length
				)
			}
			return (
				this.$offers.isLoaded &&
				!this.$offers.isRefreshing &&
				!this.$offers.data.length
			)
		},
		filterSelectedProduct() {
			return this.selectedType == 'products' ? this.$products : this.$offers
		},
		selectedCategory() {
			// return []
			return this.$productCategoriesForMarketing.data.length
				? this.$productCategoriesForMarketing.data.find(
						cat => cat.id == this.filters.mainCategory
				  )
				: {}
		},
		maxQuantity() {
			if (!this.onHoverProduct || !this.onHoverProduct.isSpecifiedQuantity) {
				return Infinity
			}
			return +this.onHoverProduct.quantity
		},
		onFetchFunction() {
			return this.currentTab === 'offers'
				? this.onFetchOffers
				: this.onFetchProducts
		},
	},
	methods: {
		...mapActions('products', ['getProducts']),
		...mapActions('offers', ['getRunningOffers']),
		...mapActions('category', ['getCategoriesForProduct']),
		onChangeTab(tab) {
			const v = { ...this.cacheFilter }
			this.cacheFilter = { ...this.filters }

			this.filters = { ...this.filters, ...v }
			this.currentTab = tab.value
		},
		onSelectCategory(id) {
			id = this.filters.mainCategory =
				this.filters.mainCategory != id ? +id : ''
			changeLocationQuery(
				queryString.stringify({
					...queryString.parse(),
					selectedCategory: id,
				})
			)
			const query = omitEmpties({ page: 1, mainCategory: id })
			this.onFetchFunction(query)
		},
		getQuery() {
			const obc = this.filters.orderByColumn
			return {
				...this.filters,
				perPage: 24,
				page: this.$products.currentPage,
				mainCategory: this.filters.mainCategory,
				priceRangeUp: this.filters.priceRangeUp || null,
				priceOrderBy: obc === 'lowToHigh' ? 'asc' : 'desc',
				orderByColumn: obc === 'newItems' ? 'created_at' : 'price',
			}
		},
		getOffersQuery() {
			const obc = this.filters.orderByColumn
			return {
				...this.filters,
				perPage: 24,
				page: this.$offers.currentPage,
				mainCategory: this.filters.mainCategory,
				priceRangeUp: this.filters.priceRangeUp || null,
				orderByDirection: obc === 'lowToHigh' ? 'asc' : 'desc',
				orderByColumn: obc === 'newItems' ? 'created_at' : 'final_price',
			}
		},
		onFetchProducts(queries = {}) {
			return this.getProducts(
				omitEmpties({
					...this.getQuery(),
					...queries,
				})
			)
		},
		onFetchOffers(queries = {}) {
			return this.getRunningOffers(
				omitEmpties({
					...this.getOffersQuery(),
					...queries,
				})
			)
		},
		onIntersect(_, __, isIntersecting) {
			if (this.$products.data.length >= this.$products.total) return
			if (!isIntersecting || this.$products.isRefreshing) return

			this.onFetchProducts({
				paginate: true,
				page: this.$products.currentPage + 1,
			})
		},
		onIntersectOffers(_, __, isIntersecting) {
			if (this.$offers.data.length >= this.$offers.total) return
			if (!isIntersecting || this.$offers.isRefreshing) return

			this.onFetchOffers({
				paginate: true,
				page: this.$offers.currentPage + 1,
			})
		},
		handleOnHover(item) {
			if (this.scroll.isScrolling()) return

			this.showProductDialog = true
			this.onHoverProduct = item
		},
		handleOnLeave() {
			this.showProductDialog = false
			setTimeout(() => {
				this.onHoverProduct = null
			}, 1000)
		},
		formatPrice(price) {
			console.log(price)
			return numberWithCommas(price)
		},
		goToSingleProduct(id) {
			console.log('goToSingleProduct')
			this.$router.push(`product/${id}`)
		},
		async sortProduts() {
			await this.onFetchFunction({ page: 1 })
			this.sortByDrawer = false
		},
		async filterProducts() {
			await this.onFetchFunction({ page: 1 })
			this.filterDrawer = false
		},
		refetchFavorite() {
			console.log('refetchFavorite')
		},
		async onChangeQuantity(payload) {
			if (!this.isInCart) return this.onAddToCart()

			this.isUpdating = true
			await this.updateCartItem({
				...this.cartItem,
				quantity: payload,
			})
			this.isUpdating = false
		},
		async onAddToCart() {
			if (this.isUpdating) return
			this.isUpdating = true
			const [err] = await this.$store.dispatch('cart/addToCart', {
				userId: this.$currentUser.id,
				cartableType: 'product',
				quantity: this.quantity,
				cartableId: this.onHoverProduct.id,
			})
			this.isUpdating = false
			if (!err) this.$toast.success(this.$t('success.addedToCart'))
			else this.$toast.error(this.$t('error.errorAddingCart'))
		},
	},
}
</script>

<style lang="scss">
.custom-outline-text-field.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.price-range .v-slider__thumb {
	background-color: rgb(255, 255, 255);
	border-color: #d9d9d9 !important;
	border: 1px solid;
	width: 32px !important;
	height: 32px !important;
}
.price-range .v-slider__track-container {
	height: 8px !important;
}
.price-range .v-slider__track-fill,
.v-slider__track-background {
	border-radius: 6px !important;
}
.price-range .v-slider__thumb:after {
	display: block;
	width: 18px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	margin-top: 1px;
}
</style>
